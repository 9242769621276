import { maskPN } from '@axa-asia/hk-emma-web-module/lib/ga-lib/MaskingUtil';
import { getClientConfig, isEmmaApp } from '@axa-asia/utils';
import {
  AppGA3Event,
  AppGA4Event,
  TrackScrollDepthDirection,
  WebGA3Event,
} from './enums';
import { GaScreenNameMapping, Params, TcWindow } from './types';
import {
  createArrayItemByEcommerceApi,
  getScreenName,
  getValidToken,
} from './utils';

const tcWindow = (typeof window !== 'undefined' &&
  window) as unknown as TcWindow;

class GaTracker {
  private static instance: GaTracker;
  private static axaId: string | null = '';

  public static setAxaId(id: string) {
    GaTracker.axaId = id;
  }

  retrieveAxaId = async () => {
    // Get axaId from instance
    if (GaTracker.axaId) return GaTracker.axaId;
    try {
      const { EmmaFactory } = await import('@axa-asia/hk-emma-js');

      const config = getClientConfig();
      // Get axaId from client config or EmmaJS
      const token = config.authApp
        ? config.authApp
        : (await EmmaFactory.createInstance()).auth.token;

      const axaId = getValidToken(token);
      if (!axaId) throw new Error('Invalid axaId');
      GaTracker.setAxaId(axaId);
      return axaId;
    } catch (error) {
      console.error(error);
      return '';
    }
  };

  /**
   * KPI #0  - default page view
   *
   * It must use the exact URL instead of screen name
   */
  trackDefaultPageView(userId: string, currentUrl = '') {
    const spaCurrentUrl = `SPA:${currentUrl}`.replace(/(\/)$/, '');
    const maskedSpaCurrUrl = maskPN(spaCurrentUrl);

    this.trackAppGa3Event(AppGA3Event.SCREEN_VIEW, { page: maskedSpaCurrUrl });
    if (!isEmmaApp) {
      this.trackWebGa3Event(WebGA3Event.SCREEN_VIEW, {
        page: spaCurrentUrl,
        AXAID: userId,
      });
    }
  }

  /**
   * Track default page view
   * GA4 KPI #0
   */
  trackDefaultPageViewGA4 = (
    curr_screen_name: string,
    previous_screen_name: string,
    curr_screen_path: string,
  ) => {
    if (isEmmaApp) {
      const spa_curr_screen_name = maskPN(
        `SPA:${curr_screen_name}`.replace(/(\/)$/, ''),
      );
      const spa_previous_screen_name = maskPN(
        `SPA:${previous_screen_name}`.replace(/(\/)$/, ''),
      );
      const spa_curr_screen_path = maskPN(
        `SPA:${curr_screen_path}`.replace(/(\/)$/, ''),
      );

      import('@axa-asia/hk-emma-js').then((mod) => {
        mod.EmmaFactory.createInstance().then((e) => {
          e.instance.trackScreen(
            spa_curr_screen_name,
            spa_previous_screen_name,
            spa_curr_screen_path,
          );
        });
      });
    }
  };

  /** KPI #27 - Track download document */
  trackDownloadDocument = (documentName: string, product: string) => {
    this.trackWebGa3Event('documentDownload', { product, documentName });
    this.trackAppGa3Event('documentDownload', { product, documentName });
    this.trackAppGa4Event(AppGA4Event.DOWNLOAD_DOCUMENT, {
      product,
      document_name: documentName,
    });
  };

  /**  KPI #47 The scroll depth users scroll vertically and horizontally on the specified screens */
  async trackScrollDepth(
    ratio: number,
    direction: TrackScrollDepthDirection,
    screenName: string,
    axaId: string,
    gaScreenNameMapping: GaScreenNameMapping,
    getResolvedGaScreenName?: (pathname: string) => string,
  ) {
    // GA3
    this.trackAppGa3Event(AppGA3Event.SCROLL_DEPTH, {
      action: `${direction} - ${ratio}%`,
      screenName: maskPN(screenName),
      axaId,
    });
    if (!isEmmaApp) {
      this.trackWebGa3Event(WebGA3Event.SCROLL_DEPTH, {
        scrollDirection: direction,
        scrolldepthThreshold: `${ratio}%`,
        screenName,
        axaId,
      });
    }

    // GA4
    const screen_path = maskPN(screenName);
    let mappedScreenName: string;
    if (getResolvedGaScreenName) {
      mappedScreenName = getResolvedGaScreenName(screen_path);
    } else {
      mappedScreenName = (await getScreenName(
        screen_path,
        gaScreenNameMapping,
      )) as string;
    }
    this.trackAppGa4Event(AppGA4Event.SCROLL_DEPTH, {
      screen_path,
      mappedScreenName,
      direction,
      ratio,
    });
  }

  /** KPI #62 - all CTA button / tab / link text */
  async clickCTAButton(
    event: string,
    displayedButtonText: string,
    screenName: string,
    userId: string,
    gaScreenNameMapping: GaScreenNameMapping,
    getResolvedGaScreenName?: (pathname: string) => string,
  ) {
    const MAX_LENGTH = 100;
    const trimmedBtnText = displayedButtonText.substring(0, MAX_LENGTH);

    if (!isEmmaApp) {
      this.trackWebGa3Event(event, {
        displayedButtonText: trimmedBtnText,
        screenName,
        AXAID: userId,
      });
    }

    const screen_path = maskPN(screenName);
    let convertedScreenName: string;
    if (getResolvedGaScreenName) {
      convertedScreenName = getResolvedGaScreenName(screen_path);
    } else {
      convertedScreenName = (await getScreenName(
        screen_path,
        gaScreenNameMapping,
      )) as string;
    }

    this.trackAppGa3Event(event, {
      displayedButtonText: trimmedBtnText,
      screenName: convertedScreenName,
      userId,
    });

    this.trackAppGa4Event(AppGA4Event.CLICK_CTA_BTN, {
      displayed_button_text: trimmedBtnText,
      screen_path,
      screen_name: convertedScreenName,
    });
  }

  /** KPI #64 - Previous Screen Tracking */
  trackPreviousScreen(
    event: string,
    displayedButtonText: string,
    previousScreenName: string,
    screenName: string,
    userId: string,
  ) {
    const maskedPreScreenName = maskPN(previousScreenName);
    const maskedScreenName = maskPN(screenName);

    // App GA3, Web GA4
    this.trackAppGa3Event(event, {
      displayedButtonText: displayedButtonText,
      previousScreenName: maskedPreScreenName,
      screenName: maskedScreenName,
      userId: userId,
    });

    if (!isEmmaApp) {
      this.trackWebGa3Event(event, {
        event: event,
        displayedButtonText: displayedButtonText,
        previousScreenName: previousScreenName,
        screenName: screenName,
        AXAID: userId,
      });
    }
  }

  /** KPI #72 - On Blur */
  trackOnBlur = (focusedField: string, screenName?: string) => {
    if (!screenName) {
      screenName = this.getScreenName();
    }

    this.trackAppGa3Event(AppGA3Event.ON_BLUR, { focusedField, screenName });
    this.trackAppGa4Event(AppGA4Event.ON_BLUR, {
      focused_field: focusedField,
      screen_name: screenName,
    });
  };

  /** KPI #35 - Track SendSubmitDoctorCriteria */
  trackSubmitDoctorCriteria = (
    product: string,
    selectedPolicy: string,
    selectedPlanName: string,
    selectedSpecialty: string,
    selectedArea: string,
    selectedDistrict: string,
    selectedInstitution: string,
    selectedDoctor: string,
  ) => {
    this.trackAppGa3Event(AppGA3Event.SUBMIT_DOCTOR_SEARCH_CRITERIA, {
      product,
      selectedPolicy,
      selectedPlanName,
      selectedSpecialty,
      selectedArea,
      selectedDistrict,
      selectedInstitution,
      selectedDoctor,
    });
    this.trackWebGa3Event(WebGA3Event.SUBMIT_DOCTOR_SEARCH_CRITERIA, {
      product,
      selectedPolicy,
      selectedPlanName,
      selectedSpecialty,
      selectedArea,
      selectedDistrict,
      selectedInstitution,
      selectedDoctor,
    });
    this.trackAppGa4Event(AppGA4Event.SUBMIT_DOCTOR_SEARCH_CRITERIA, {
      product,
      selected_policy: selectedPolicy,
      selected_plan_name: selectedPlanName,
      selected_specialty: selectedSpecialty,
      selected_area: selectedArea,
      selected_district: selectedDistrict,
      selected_institution: selectedInstitution,
      selected_doctor: selectedDoctor,
    });
  };

  /** KPI #36 - Track SendSearchDoctorPhoneCall */

  trackSendSearchDoctorPhoneCall = (
    product: string,
    providerName: string,
    providerPhone: string,
    areaSelected: string,
    currentLocation: string,
  ) => {
    this.trackWebGa3Event(WebGA3Event.FIND_DOCTOR_PHONE_CALL, {
      product,
      providerName,
      providerPhone,
      areaSelected,
      currentLocation,
    });
    this.trackAppGa3Event(AppGA3Event.FIND_DOCTOR_PHONE_CALL, {
      product,
      providerName,
      providerPhone,
      areaSelected,
      currentLocation,
    });
    this.trackAppGa4Event(AppGA4Event.FIND_DOCTOR_PHONE_CALL, {
      product,
      service_provider_name: providerName,
      service_provider_phone: providerPhone,
      area_selected: areaSelected,
      current_location: currentLocation,
    });
  };

  /** KPI #37 - Track SearchDoctor */

  trackSearchDoctor = (
    product: string,
    icon: string,
    areaSelected: string,
    currentLocation: string,
  ) => {
    this.trackWebGa3Event(WebGA3Event.SEARCH_DOCTOR, {
      product,
      icon,
      areaSelected,
      currentLocation,
    });
    this.trackAppGa3Event(AppGA3Event.SEARCH_DOCTOR, {
      product,
      icon,
      areaSelected,
      currentLocation,
    });
    this.trackAppGa4Event(AppGA4Event.SEARCH_DOCTOR, {
      product: product,
      icon: icon,
      area_selected: areaSelected,
      current_location: currentLocation,
    });
  };

  /** KPI #73 - Track AccessLocation */

  trackAccessLocation = async (
    product: string,
    accessLocationOption: string,
    gaScreenNameMapping: GaScreenNameMapping,
  ) => {
    const screen_path = this.getScreenName();

    const mappedScreenName = (await getScreenName(
      screen_path,
      gaScreenNameMapping,
    )) as string;

    this.trackWebGa3Event(WebGA3Event.ACCESS_LOCATION, {
      product,
      accessLocationOption,
      mappedScreenName,
    });
    this.trackAppGa3Event(AppGA3Event.ACCESS_LOCATION, {
      product,
      accessLocationOption,
      mappedScreenName,
    });
    this.trackAppGa4Event(AppGA4Event.ACCESS_LOCATION, {
      product,
      access_location_option: accessLocationOption,
      screen_name: mappedScreenName,
    });
  };

  /** KPI #74 - Track ApplyDoctorFilter */
  trackApplyDoctorFilter = async (
    product: string,
    selectedSpecialty: string,
    selectedArea: string,
    consultationFormat: string,
    gaScreenNameMapping: GaScreenNameMapping,
  ) => {
    const screen_path = this.getScreenName();

    const mappedScreenName = (await getScreenName(
      screen_path,
      gaScreenNameMapping,
    )) as string;

    this.trackAppGa3Event(AppGA3Event.APPLY_DOCTOR_FILTER, {
      product,
      selectedSpecialty,
      selectedArea,
      consultationFormat,
      mappedScreenName,
    });
    this.trackWebGa3Event(WebGA3Event.APPLY_DOCTOR_FILTER, {
      product,
      selectedSpecialty,
      selectedArea,
      consultationFormat,
      mappedScreenName,
    });
    this.trackAppGa4Event(AppGA4Event.APPLY_DOCTOR_FILTER, {
      product,
      selected_specialty: selectedSpecialty,
      selected_area: selectedArea,
      consultation_format: consultationFormat,
      screen_name: mappedScreenName,
    });
  };

  /** Track Popup Open */
  trackNavigationOverlayDisplay = async (
    popupName: string,
    gaScreenNameMapping: GaScreenNameMapping,
  ) => {
    const screen_path = this.getScreenName();

    const config = getClientConfig();

    try {
      const axaId = await this.retrieveAxaId();
      const mappedScreenName = (await getScreenName(
        screen_path,
        gaScreenNameMapping,
      )) as string;

      const popupScreenName = `${mappedScreenName}#${popupName}`;

      this.trackDefaultPageView(axaId, popupScreenName);

      this.trackDefaultPageViewGA4(
        popupScreenName,
        mappedScreenName,
        popupScreenName,
      );
    } catch (e) {
      console.log(e);
    }
  };

  trackCTAButton = async (
    displayedButtonText: string,
    gaScreenNameMapping: GaScreenNameMapping,
  ) => {
    const screen_path = this.getScreenName();

    const config = getClientConfig();

    try {
      const axaId = await this.retrieveAxaId();
      console.log(
        'clickCTAButton',
        displayedButtonText,
        screen_path,
        axaId,
        gaScreenNameMapping,
      );

      this.clickCTAButton(
        'clickCTAButton',
        displayedButtonText,
        screen_path,
        axaId,
        gaScreenNameMapping,
      );
    } catch (e) {
      console.log(e);
    }
  };

  /** Track Radio Input */
  trackRadioInput = this.trackCTAButton;

  /** ADD YOUR GA EVENT ON TOP OF THIS LINE */

  // TODO handle screenNameMapping
  getScreenName = (screenName?: string) => {
    const MOB_PREFIX = 'mob';
    const pageName =
      screenName ||
      (typeof window !== 'undefined' ? window.location.pathname : '');
    const convertedPageName = maskPN(pageName.replace(`/${MOB_PREFIX}`, ''));
    return convertedPageName;
  };

  /** Track Web GA3 */
  trackWebGa3Event = (event: string, params: Params) => {
    try {
      if (!tcWindow.tC) return;

      tcWindow.tC.event[event](this, {
        ...params,
      });
    } catch (error) {
      console.error(error);
    }
  };

  /** Track App GA3, Web GA4 */
  trackAppGa3Event = (event: string, params: Params) => {
    try {
      if (!tcWindow.dataLayer) return;

      tcWindow.dataLayer.push({
        event,
        ...params,
      });
    } catch (error) {
      console.error(error);
    }
  };

  /** Track App GA4 */
  trackAppGa4Event = (event: string, params: Params) => {
    try {
      if (isEmmaApp) {
        import('@axa-asia/hk-emma-js').then((mod) => {
          mod.EmmaFactory.createInstance().then((e) => {
            e.instance.trackEvent(event, {
              ...params,
            });
          });
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  /** Track Events with Array */
  trackWithGa4Array = (
    items: Parameters<typeof createArrayItemByEcommerceApi>[0][],
  ) => {
    // @ts-expect-error follow the GA4 format
    // https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#clear_the_ecommerce_object
    tcWindow.dataLayer.push({ ecommerce: null });

    const ecommerce = {
      items: items.map((item) => createArrayItemByEcommerceApi(item)),
    };

    this.trackAppGa3Event('view_item_list', { ecommerce });
    this.trackAppGa4Event('view_item_list', { ecommerce });
  };

  public static get Instance() {
    if (!this.instance) {
      this.instance = new GaTracker();
    }
    return this.instance;
  }
}

export { GaTracker };
