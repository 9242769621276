import './ReactotronConfig';
// import "core-js/stable";
// import "regenerator-runtime/runtime";
// import 'react-app-polyfill/ie11';
import '@axa-asia/emma-stylesheet/styles/frontend.scss';
import '@axa-asia/emma-stylesheet/lib/index.css';

import config from './package.json';
import reducers from './reducers';
import appSaga from './sagas/appSaga';
import mobileModuleConfigSaga from './sagas/mobileModuleConfigSaga';
import { configureStore as initStore, select } from './stores';
import {
  initCookies,
  initModules,
  registerRoot,
  fetchAppConfig,
  fetchUserConfig,
  initTMS,
  initGaClickableElements,
  initLogPreviousUrl,
  initLazyModules,
  isMobileRoute,
  registerMobileRoot,
  fetchWebUserConfigFromApp,
  initPreloadModules,
  initLang,
  initCssHack,
  initNewSession,
} from './actions/appActions';
import { initTabId } from './utils/TabUtil';
import initLocale from './locales';
import initApp from './initApp';
import { initEnv } from './env';
import { initAuth, initMobileAuth } from './actions/authActions';
import { ModuleConfig } from './types/configTypes';
import userSaga from './sagas/userSaga';
import sideBarItemSaga from './sagas/sideBarItemSaga';
import myPrivilegesOverlaySaga from './sagas/myPrivilegesOverlaySaga';
import uiSaga from './sagas/uiSaga';
import tncDisclaimerSaga from './sagas/tncDisclaimerSaga';
import { setDateConfig } from './utils/common';
import { getToken } from './selectors/authSelectors';
import authReducer from './reducers/authReducer';
import appReducer from './reducers/appReducer';
import { isApp, __DEV__, getValidToken } from './utils';
import env from './env';
import uiReducer from './reducers/uiReducer';
import customerProfileReducer from './modules/emma-customer-profile/reducers';
import checkEBCoverageReducer from './modules/check-coverage/reducers/checkEBCoverageReducer';
import womanProductReducer from './modules/check-coverage/reducers/checkCoverageReducer';
import fertilitysolutionReducer from './modules/ps-fertilitysolution/reducers';
import cosmeticdermatologyReducer from './modules/ps-cosmeticdermatology/reducers';
import meditationReducer from './modules/ps-meditation/reducers';
import teleconsultationReducer from './modules/ps-teleconsultation/reducers';
import storyTalerReducer from './modules/ps-storytaler/reducers';
import wellnessprogrammeReducer from './modules/ps-wellnessprogramme/reducers';
import fanclReducer from './modules/fancl/reducers/fanclReducer';
import offersReducer from './modules/offers/reducers';
import promoReducer from './modules/emma-axa-promotional-offer/reducers/promoReducer';
import userReducer from './reducers/userReducer';
import vhisReducer from './modules/axagoal/reducers';
import historyReducer from './reducers/historyReducer';
import goGreenSurveyReducer from './modules/go-green-survey/reducers';
import aktivoHealthSurveyReducer from './modules/aktivo-health-survey/reducers';
import aktivoOnboardingReducer from './modules/aktivo-onboarding/reducers';
import exclusiveOffersReducer from './modules/ps-exclusiveoffers/reducers';
import happyAgingReducer from './modules/ps-happyaging/reducers';
import pinkMedicalReducer from './modules/ps-pinkmedical/reducers';
import valueAddedServiceReducer from './modules/ps-valueaddedservice/reducers';

import {
  fetchUserGroups,
  updateUserGroupID,
} from './modules/google-optimize-ab-test/actions/userGroupActions';
import { initExperiment } from './actions/abTestActions';
import abTestReducer from './reducers/abTestReducer';
import deleteAccountReducer from './modules/delete-account/reducers';
import aktivoIndividualChallengeReducer from './modules/aktivo-individual-challenge/reducers';
import pnReducer from './reducers/policyNumberReducer';
import policyNumberSaga from './sagas/policyNumberSaga';
import generalErrorCodeReducer from './modules/emma-general-error-code/reducers';
import { startAppInsight } from './utils/appInsights';
import { setInitialLandingUrl } from './utils';
import { AppInsightsClient } from '@axa-asia/app-insights-client';
import { isMobileBrowser } from '@axa-asia/utils';
import {
  initIdleTimeout,
  shouldPerformAutoLogout,
  putSignoutAction,
} from './utils/authUtil';

// This is used to handle some cases where user manually change the URL to access the page
setInitialLandingUrl();

startAppInsight();

if (!isMobileBrowser()) {
  import('@axa-asia/emma-stylesheet/styles/common/scrollbar.scss');
  const root = document.getElementsByTagName('html')[0];
  root.className += 'axa-scrollbar';
}

export default {
  config,

  // Web portal reducers and sagas
  reducers,
  sagas: [
    appSaga,
    userSaga,
    sideBarItemSaga,
    myPrivilegesOverlaySaga,
    uiSaga,
    tncDisclaimerSaga,
    policyNumberSaga,
  ],

  // Mobile reducers and sagas
  mobileReducers: {
    app: { reducer: appReducer },
    abTest: { reducer: abTestReducer },
    pn: { reducer: pnReducer, persist: true },
    auth: { reducer: authReducer, persist: true },
    history: { reducer: historyReducer, persist: true },
    ui: { reducer: uiReducer },
    user: { reducer: userReducer },
    customerProfile: { reducer: customerProfileReducer },
    checkEBCoverage: { reducer: checkEBCoverageReducer },
    womanProduct: { reducer: womanProductReducer },
    fertilitysolution: { reducer: fertilitysolutionReducer },
    cosmeticdermatology: { reducer: cosmeticdermatologyReducer },
    neurummedication: { reducer: meditationReducer },
    teleconsultation: { reducer: teleconsultationReducer },
    storytaler: { reducer: storyTalerReducer },
    wellnessoffers: { reducer: wellnessprogrammeReducer },
    fancl: { reducer: fanclReducer },
    offers: { reducer: offersReducer },
    promotionalOffer: { reducer: promoReducer },
    vhis: { reducer: vhisReducer },
    goGreenSurvey: { reducer: goGreenSurveyReducer },
    aktivoHealthSurvey: { reducer: aktivoHealthSurveyReducer },
    aktivo: { reducer: aktivoOnboardingReducer },
    deleteAccount: { reducer: deleteAccountReducer },
    aktivoIndividualChallenge: { reducer: aktivoIndividualChallengeReducer },
    generalErrorCode: { reducer: generalErrorCodeReducer },
    exclusiveoffers: { reducer: exclusiveOffersReducer },
    valueaddedservice: { reducer: valueAddedServiceReducer },
    happyaging: { reducer: happyAgingReducer },
    pinkmedical: { reducer: pinkMedicalReducer },
  },
  mobileSagas: [mobileModuleConfigSaga, uiSaga, policyNumberSaga],
};

document.domain = window.location.hostname.split('.').slice(-3).join('.');

(async () => {
  if (isMobileRoute()) {
    /**
     * New init app flow for new modules.
     *  ONLY for app.
     *  ONLY for those routes starting with '/en/emma/mob'.
     *  FORCE to enable this by
     *    (1) turn enableMobileRoute to true in route config.
     *    (2) enable lazy loading by adding config in lazyLoadConfig.js
     *  This flow hasn't dependency on app-info API and post-login API for better performance.
     */
    await Promise.all([initEnv(), initLocale(), initLazyModules()]);
    await Promise.all([initStore()]);
    await Promise.all([fetchWebUserConfigFromApp()]); // get post-login from app

    /**
     * Load auth
     *  (1) If local dev and non-app access (means you test on local web browser),
     *      get auth and module config as normal web portal flow to remove dependency of app
     *  (2) If non-local, get auth and module config from app
     */
    let isAuthLoadSuccess = false;
    if (__DEV__ && !isApp()) {
      // If local development, run web portal flow
      await fetchAppConfig(); // fetch app-info
      await initAuth(); // load token
      await fetchUserConfig(); // fetch post-login
      await fetchUserGroups();
      const token = select(getToken);
      isAuthLoadSuccess = !!token;
    } else {
      // If non-local, load auth from app
      isAuthLoadSuccess = await initMobileAuth();
    }

    // Load root container
    const isShowErrorPage = !isAuthLoadSuccess;
    await registerMobileRoot(isShowErrorPage);
  } else {
    /**
     * Existing init app flow for existing modules accessed by webportal and app
     *  ONLY For (1) web portal AND (2) app's existing modules (old modules) using normal path '/en/emma/...'.
     *  This flow has dependency on app-info API and post-login API.
     *  Not force to enable lazy loading
     */
    await Promise.all([
      initEnv(),
      initLocale(),
      initModules(),
      initLazyModules(),
    ]);

    // app-info API
    const [_, appConfigResult] = await Promise.all([
      initStore(),
      fetchAppConfig(),
      initNewSession(),
    ]);
    let fetchUserConfigResult = true;
    if (appConfigResult) {
      // token managment
      try {
        await initAuth();
        /** On browser web protal, check if need to perform auto logout */
        if (shouldPerformAutoLogout()) {
          putSignoutAction();
          return;
        }
        initIdleTimeout();
        // fetch post-login
        fetchUserConfigResult = await fetchUserConfig();
        // Fetch UserGroupID
        if (fetchUserConfigResult) {
          await fetchUserGroups();
        }
      } catch (e) {
        console.log('error on init auth', e);
        fetchUserConfigResult = false;
      }
    }
    await registerRoot(appConfigResult && fetchUserConfigResult);
  }

  /** Start App Insights again with AXAID context */
  const token = select(getToken);
  const axaId = getValidToken(token);
  AppInsightsClient.getInstance().updateAccountId(axaId);

  if (env.EMMA_SYSTEM_DATE) {
    setDateConfig().config({
      dateString: env.EMMA_SYSTEM_DATE,
    });
  }

  /**
   * Please note that the following init functions must NOT depend on profile-api config
   * Because this is shared with mobile route
   */
  initTabId();
  initLang();
  // HKEMMA-100917 - GA3 Decommission - Remove TC Scripts
  // await initTMS();
  await initExperiment();
  await initGaClickableElements();
  await initLogPreviousUrl();
  await initCookies();
  await initApp();
  initCssHack();

  // Update User Group ID to GA4, when gtag is ready
  updateUserGroupID();

  // preload modules to improve lazy loading (ONLY for web portal access)
  if (!isMobileRoute() && !isApp()) {
    initPreloadModules();
  }
})();
