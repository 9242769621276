export enum TrackScrollDepthDirection {
  VERTICAL = 'Vertical',
  HORIZONTAL = 'Horizontal',
}

export enum WebGA3Event {
  SCREEN_VIEW = 'virtualPageview',
  ON_BLUR = 'onBlur',
  SCROLL_DEPTH = 'trackScrollDepth',
  SUBMIT_DOCTOR_SEARCH_CRITERIA = 'submitDoctorCriteria',
  FIND_DOCTOR_PHONE_CALL = 'finddoctorPhoneCall',
  SEARCH_DOCTOR = 'searchDoctor',
  ACCESS_LOCATION = 'accessLocation',
  APPLY_DOCTOR_FILTER = 'applyDoctorFilter',
}

export enum AppGA3Event {
  SCREEN_VIEW = 'screenView',
  ON_BLUR = 'onBlur',
  SCROLL_DEPTH = 'trackScrollDepth',
  SUBMIT_DOCTOR_SEARCH_CRITERIA = 'submitDoctorCriteria',
  FIND_DOCTOR_PHONE_CALL = 'finddoctorPhoneCall',
  SEARCH_DOCTOR = 'searchDoctor',
  ACCESS_LOCATION = 'accessLocation',
  APPLY_DOCTOR_FILTER = 'applyDoctorFilter',
}

export enum AppGA4Event {
  DOWNLOAD_DOCUMENT = 'download_document',
  CLICK_CTA_BTN = 'click_cta_button',
  ON_BLUR = 'on_blur',
  SCROLL_DEPTH = 'scroll_depth',
  SUBMIT_DOCTOR_SEARCH_CRITERIA = 'submit_doctor_search_criteria',
  FIND_DOCTOR_PHONE_CALL = 'call_doctor_from_search',
  SEARCH_DOCTOR = 'search_doctor',
  ACCESS_LOCATION = 'access_location',
  APPLY_DOCTOR_FILTER = 'apply_doctor_filter',
}
