import { AxaAuth, AdditionalAxaAuth, AktivoConfig } from '../types/authTypes';

import {
  requestToken,
  tokenRefreshRequired,
  refreshToken as refreshTokenFunc,
} from '../utils/myAxaFetchWithToken';
import { select, put } from '../stores';
import {
  getRefreshToken,
  getAdditionalAuthCodes,
  getAuthCode,
  getUrlToken,
  getToken,
  getTokenExpireIn,
  getTokenFetchTime,
} from '../selectors/authSelectors';
import { fetchSessionAccessToken } from './sessionAction';
import {
  getTokenScope,
  getAdditionalAccessTokens as getAdditionalAccessTokensConfig,
  getMaamRedirectUri,
} from '../selectors/configSelectors';

import env from '../env';
import {
  isApp,
  stringFormatter,
  resolveLocalizedText,
  __DEV__,
} from '../utils';

import moment from 'moment';

import { EmmaFactory } from '@axa-asia/hk-emma-js';
import { setLastActiveTimeToNow } from '../utils/authUtil';

export const SIGN_OUT = 'SIGN_OUT';

export const FETCH_AUTH_CODE_SUCCESS = 'FETCH_AUTH_CODE_SUCCESS';
export const FETCH_ADDITIONAL_AUTH_CODE_SUCCESS =
  'FETCH_ADDITIONAL_AUTH_CODE_SUCCESS';

export const FETCH_ACCESS_TOKEN_REQUEST = 'FETCH_ACCESS_TOKEN_REQUEST';
export const FETCH_ACCESS_TOKEN_STARTED = 'FETCH_ACCESS_TOKEN_STARTED';
export const FETCH_ACCESS_TOKEN_SUCCESS = 'FETCH_ACCESS_TOKEN_SUCCESS';
export const FETCH_ADDITIONAL_ACCESS_TOKEN_SUCCESS =
  'FETCH_ADDITIONAL_ACCESS_TOKEN_SUCCESS';
export const REFRESH_ADDITIONAL_ACCESS_TOKEN_SUCCESS =
  'REFRESH_ADDITIONAL_ACCESS_TOKEN_SUCCESS';
export const FETCH_ACCESS_TOKEN_FAILURE = 'FETCH_ACCESS_TOKEN_FAILURE';

export const REMOVE_EXPIRED_TOKEN = 'REMOVE_EXPIRED_TOKEN';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE';

export const CLEAR_ID_TOKEN = 'CLEAR_ID_TOKEN';

export const UPDATE_FORCE_LOGOUT_FLAG = 'UPDATE_FORCE_LOGOUT_FLAG';

export const CHECK_FORCE_LOGOUT = 'CHECK_FORCE_LOGOUT';

export const POST_REDIRECT_URI = 'POST_REDIRECT_URI';

export const SHOW_RELOGIN_POPUP = 'SHOW_RELOGIN_POPUP';

export const HIDE_RELOGIN_POPUP = 'HIDE_RELOGIN_POPUP';

export const FETCH_AKTIVO_CONFIG_SUCCESS = 'FETCH_AKTIVO_CONFIG_SUCCESS';

export const showReloginPopup = () => ({
  type: SHOW_RELOGIN_POPUP,
});

export const hideReloginPopup = () => ({
  type: HIDE_RELOGIN_POPUP,
});

export const checkForceLogout = () => ({
  type: CHECK_FORCE_LOGOUT,
});

export const updateForceLogoutFlag = (isForceLogout: boolean) => ({
  type: UPDATE_FORCE_LOGOUT_FLAG,
  isForceLogout,
});

export const signOut = () => ({
  type: SIGN_OUT,
});

export const setPostRedirectUri = (postRedirectUri: string) => ({
  type: POST_REDIRECT_URI,
  postRedirectUri,
});

export const fetchAccessTokenSuccess = (auth: AxaAuth) => ({
  type: FETCH_ACCESS_TOKEN_SUCCESS,
  auth,
});

export const fetchAktivoConfigSuccess = (aktivoConfig: AktivoConfig) => ({
  type: FETCH_AKTIVO_CONFIG_SUCCESS,
  aktivoConfig,
});

export const fetchAuthCodeSuccess = (code: string) => ({
  type: FETCH_AUTH_CODE_SUCCESS,
  code,
});

export const fetchAdditonalAuthCodeSuccess = (codeOfScope: {
  [code: string]: string;
}) => ({
  type: FETCH_ADDITIONAL_AUTH_CODE_SUCCESS,
  codeOfScope,
});

export const removeExpiredToken = () => ({
  type: REMOVE_EXPIRED_TOKEN,
});

export const refreshTokenSuccess = (auth: AxaAuth) => ({
  type: REFRESH_TOKEN_SUCCESS,
  auth,
});

export const refreshAdditionalAccessTokenSuccess = (
  additionalAccessToken: AdditionalAxaAuth,
) => ({
  type: REFRESH_ADDITIONAL_ACCESS_TOKEN_SUCCESS,
  additionalAccessToken,
});

export const fetchAdditionalAccessTokenSuccess = (
  additionalAccessTokens: Array<AdditionalAxaAuth>,
) => ({
  type: FETCH_ADDITIONAL_ACCESS_TOKEN_SUCCESS,
  additionalAccessTokens,
});

export const refreshTokenFailure = (error: any) => ({
  type: REFRESH_TOKEN_FAILURE,
  error,
});

export const clearIdToken = () => ({
  type: CLEAR_ID_TOKEN,
});

export const fetchAccessTokenStarted = () => ({
  type: FETCH_ACCESS_TOKEN_STARTED,
});

export const fetchAccessTokenFailure = (payload: any) => ({
  type: FETCH_ACCESS_TOKEN_FAILURE,
  payload,
});

const tokenExpired = (auth: AxaAuth): boolean => {
  const expiresIn: number = auth.expiresIn;

  if (expiresIn) {
    const tokenFetchTime: string = auth.fetchTime;
    const pastSecond = Math.abs(moment().diff(tokenFetchTime, 'second'));

    return pastSecond > expiresIn * 0.9;
  } else {
    return false;
  }
};

/**
 * This is to get auth from mobile.
 * ONLY for mobile route where enableMobileRoute is enabled.
 * This is NOT allowed to depend on profile-api config.
 * This has NOT refresh token logic which is same as other webview project.
 */
export const initMobileAuth = () =>
  new Promise<boolean>((res) => {
    EmmaFactory.createInstance()
      .then((e) => {
        put(fetchAktivoConfigSuccess(e.aktivoConfig));
        if (tokenExpired(e.auth)) {
          e.instance.getAxaAuth(true).then((auth) => {
            put(fetchAccessTokenSuccess(auth));
            res(true);
          });
        } else {
          put(fetchAccessTokenSuccess(e.auth));
          res(true);
        }
      })
      .catch((error) => {
        console.error('Failed to get token from mobile', error);
        res(false);
      });
  });

export const initAuth = () =>
  new Promise<void>((res, rej) => {
    (!isApp()
      ? Promise.resolve(false)
      : EmmaFactory.createInstance()
          .then((e) => {
            // comment: remove A-HKG-0018-V010
            // put(fetchSessionAccessToken(e.auth.token));
            put(fetchAccessTokenSuccess(e.auth));

            // setImmutableAxaToken(e.auth.token);
            // setAxaAuth({
            //   token: e.auth.token,
            //   expiresIn: e.auth.expiresIn,
            //   refreshToken: e.auth.refreshToken,
            //   fetchTime: e.auth.fetchTime,
            // });
            return Promise.resolve(true);
          })
          .catch((err) => {
            return Promise.resolve(false);
          })
    ).then((authorized) => {
      if (authorized) {
        res();
      } else {
        const url = new URL(window.location.href);
        const code = window.sessionStorage.getItem('adb2c_code');
        const scopeParam = window.sessionStorage.getItem('adb2c_state');

        window.sessionStorage.removeItem('adb2c_code');
        window.sessionStorage.removeItem('adb2c_state');

        if (code && scopeParam) {
          const scope = scopeParam
            .split(`:`)
            .reverse()
            .find((s) => !!s);
          const additionalAccessTokensConfig =
            select(getAdditionalAccessTokensConfig) || [];
          if (scope === select(getTokenScope)) {
            setLastActiveTimeToNow(); // Fresh login, reset idle timestamp
            put(fetchAuthCodeSuccess(code));
          } else {
            const config = additionalAccessTokensConfig.find(
              (a) => a.scope === scope,
            );
            if (config) {
              put(fetchAdditonalAuthCodeSuccess({ [config.scope]: code }));
            }
          }

          const additonalScopes = additionalAccessTokensConfig.map(
            (a) => a.scope,
          );
          const fetchedScopes = Object.keys(select(getAdditionalAuthCodes));

          const outstandingScopes = additonalScopes.filter(
            (a) => !fetchedScopes.includes(a),
          );

          if (outstandingScopes.length > 0) {
            const config = additionalAccessTokensConfig.find(
              (a) => a.scope === outstandingScopes[0],
            );
            if (config) {
              const maamUri = stringFormatter(config.maamUri, {
                redirectUri: resolveLocalizedText(config.redirectUri),
                scope: config.scope,
              });
              window.location.href = maamUri;
            }
          } else {
            const code = select(getAuthCode);
            const additionalAuthCodes = select(getAdditionalAuthCodes);
            requestToken(code, additionalAuthCodes).then(() => res());
          }
        } else {
          const refreshToken = select(getRefreshToken);
          // const token = select(getToken);
          // const expiresIn = select(getTokenExpireIn);
          // const fetchTime = select(getTokenFetchTime);

          // setAxaAuth({
          //   token,
          //   expiresIn,
          //   refreshToken,
          //   fetchTime,
          // });

          if (refreshToken && tokenRefreshRequired()) {
            refreshTokenFunc()
              .then(() => res())
              .catch((e) => rej(e));
          } else {
            res();
          }
        }
      }
    });
  });
