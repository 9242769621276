import moment from 'moment';
import _ from 'lodash';

import { put, select } from '../stores';
import { getIdleTimeoutInMinutes } from '../selectors/configSelectors';
import { getToken } from '../selectors/authSelectors';
import { isApp } from './index';
import { signOut } from '../actions/authActions';

const LAST_ACTIVE_TIME_KEY = `__EMMA_LAST_ACTIVE_TIME__`;

export const getLastActiveTime = (): string => {
  return localStorage.getItem(LAST_ACTIVE_TIME_KEY) || undefined;
};

export const setLastActiveTime = (timestamp: string) => {
  localStorage.setItem(LAST_ACTIVE_TIME_KEY, timestamp);
};

export const setLastActiveTimeToNow = () => {
  setLastActiveTime(moment().format());
};

export const putSignoutAction = () => {
  put(signOut());
};

export const shouldPerformAutoLogout = () => {
  if (isApp()) {
    return false;
  }
  const token = select(getToken);
  if (!token) {
    return false;
  }
  const idleTimeoutInMinutes = select(getIdleTimeoutInMinutes);
  const lastActiveTime = moment(getLastActiveTime());

  const diffInMinutes = Math.abs(lastActiveTime.diff(moment(), 'minute'));
  if (diffInMinutes >= idleTimeoutInMinutes) {
    return true;
  }
  return false;
};

export const idleTimeoutTimerCallback = () => {
  if (shouldPerformAutoLogout()) {
    putSignoutAction();
  }
};

export const initIdleTimeout = () => {
  idleTimeoutTimerCallback();
  setInterval(() => {
    idleTimeoutTimerCallback();
  }, 10 * 1000);
  window.addEventListener(
    'mousemove',
    _.debounce(setLastActiveTimeToNow, 3000),
  );
  window.addEventListener(
    'touchstart',
    _.debounce(setLastActiveTimeToNow, 3000),
  );
};
