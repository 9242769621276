import { useState, useEffect } from 'react';

import { maskPN } from '@axa-asia/hk-emma-web-module/lib/ga-lib/MaskingUtil';

import { MOB_PREFIX } from '../constants';
import { getScreenName } from '../utils';

export const useGaScreenName = (
  gaScreenNameMapping: Record<
    string,
    { screenPathRegex: string; screenName: string }[]
  >,
) => {
  const [gaScreenName, setGaScreenName] = useState('');

  useEffect(() => {
    const screenPath = maskPN(
      window.location.pathname.replace(`/${MOB_PREFIX}`, ''),
    );
    setGaScreenName(screenPath);
    if (gaScreenNameMapping) {
      getScreenName(screenPath, gaScreenNameMapping)
        .then((name) => {
          setGaScreenName(name as string);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [gaScreenNameMapping]);

  return gaScreenName;
};
